* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

section {
  padding: 30px 0;
  // background: linear-gradient(#8080800d, transparent 5%);

  @include tablet {
    padding: 40px 0;
  }

  @include mobile {
    padding: 40px 0;
  }

  &:first-child {
    background: linear-gradient(#8080800d, transparent 5%);
  }
}

.page-content {
  @include tablet {
    margin-top: 88px;
  }
  @include mobile {
    margin-top: 88px;
  }
}

.disabled-quantity {
  user-select: none;

  &:hover {
    cursor: no-drop;
  }
}

.post,
.page {
  margin: 0 !important;
  overflow-x: hidden;
}

.w-fit-content {
  width: fit-content !important;
}

.ql-align-center {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.ql-align-right {
  text-align: center;
  display: flex;
  justify-content: flex-end;
  margin: 6px 0;
}

.ql-align-left {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  margin: 6px 0;
}

.zoomImg {
  border: none !important;
}

// .page-content {
//   margin-top: 125px !important;

//   @include laptop-1440 {
//     margin-top: 125px !important;
//   }

//   @include laptop-1280 {
//     margin-top: 100px !important;
//   }

//   @include tablet-land {
//     margin-top: 70px !important;
//   }

//   @include tablet {
//     margin-top: 70px !important;
//   }

//   @include mobile-land {
//     margin-top: 70px !important;
//   }

//   @include mobile {
//     margin-top: 70px !important;
//   }
// }

.sticky-top {
  position: sticky;
  top: 200px;
  z-index: 1;
  @include laptop-1440 {
    top: 175px;
  }
  @include laptop-1280 {
    top: 155px;
  }
}

.fixedPosition {
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.fc-primary {
  color: $primary !important;
}

.fc-red {
  color: $red !important;
}

.container-fluid {
  padding-left: 10%;
  padding-right: 10%;

  @include mobile {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include tablet {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include tablet-land {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include laptop-1280 {
    padding-left: 3%;
    padding-right: 3%;
  }

  @include laptop-1440 {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.primary-box {
  background-image: url("../../images/question-us-background.png");
  background-size: cover;
  background-position: bottom;

  background-color: $primary;
  color: $white;
  border-radius: $border-radius;
  padding: 24px;
  position: relative;

  @include tablet {
    padding: 20px;
  }

  @include tablet-land {
    padding: 20px;
  }

  @include laptop-1280 {
    padding: 20px;
  }

  @include laptop-1440 {
    padding: 20px;
  }

  .bg-line {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 22%;
    right: 0;
    left: 44%;
    bottom: 70px;
    @include mobile {
      top: 24%;
    }
  }

  h4 {
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 60px;
    font-size: 44px;

    @include laptop-1280 {
      font-size: 36px;
    }
    @include laptop-1440 {
      font-size: 36px;
    }
    @include tablet {
      font-size: 32px;
    }
    @include tablet-land {
      font-size: 32px;
    }
    @include mobile {
      font-size: 40px;
    }
  }

  .btn {
    box-shadow: 0 0 8px 4px rgba(0,0,0, 0.09);
    background-color: rgba(255, 255, 255, 0.15);
    border-color: transparent !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.24) !important;
      border-color: rgba(255, 255, 255, 0.24) !important;
    }
  }
}

.secondary-box {
  background-color: $light;
  color: $black;
  border-radius: $border-radius;
  padding: 24px;

  @include tablet {
    padding: 20px;
  }

  @include tablet-land {
    padding: 20px;
  }

  @include laptop-1280 {
    padding: 20px;
  }

  @include laptop-1440 {
    padding: 20px;
  }

  h4 {
    font-weight: 700;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 20px;

    @include tablet {
      font-size: 22px;
    }

    @include tablet-land {
      font-size: 22px;
    }

    @include laptop-1280 {
      font-size: 25px;
    }

    @include laptop-1440 {
      font-size: 25px;
    }
  }
}

.border-radius {
  border-radius: $border-radius;
}

.h-100-vh {
  height: 100vh;
}

#template {
  .col-lg-12 {
    > div {
      @include mobile {
        flex-direction: column;
      }
    }
  }
}

.desktop {
  display: block;

  @include tablet {
    display: none;
  }

  @include mobile {
    display: none;
  }

  @include tablet-land {
    display: none;
  }

  @include mobile-land {
    display: none;
  }
}

.desktop-table {
  display: inline-table;

  @include tablet {
    display: none;
  }

  @include mobile {
    display: none;
  }

  @include tablet-land {
    display: none;
  }

  @include mobile-land {
    display: none;
  }
}

.mobile {
  display: none;

  @include tablet {
    display: block;
  }

  @include mobile {
    display: block;
  }

  @include tablet-land {
    display: block;
  }

  @include mobile-land {
    display: block;
  }
}

/*
*	Colors
*/

.c-primary {
  color: $primary !important;
}

.c-black {
  color: $black;
}

.bg-light {
  background-color: $light !important;
}

.badge-primary {
  background-color: $red !important;
  color: $white !important;
}

.overlay-black {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: fixed;
  bottom: 0;
  left: auto;
  right: -100vw;
  background: rgb(0 0 0 / 0.7);
  z-index: 9998;
  transition: all 0.2s ease-in-out;
}

.overlay-black.active {
  right: 0;
}

/*
*	Share icons
*/

.share-icons {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    margin-top: 16px;
  }

  a {
    color: $black;
  }

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light;
    font-size: 16px;
    cursor: pointer;
    transition: ease-in all 0.2s;
    margin: 0 4px;

    &:hover {
      background-color: $primary;
      color: $white;
    }

    &:first-child {
      margin-left: 16px;
    }
  }
}

/*
*	Form password show/hide
*/
.position-relative {
  input {
    padding-right: 50px !important;
  }

  i {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    cursor: pointer;
    color: $black;
  }
}

/*
*	Table
*/

table {
  .table-title {
    background-color: #f1f1f1;
    color: $black;
    font-weight: 700;
  }

  td {
    border: 0 !important;
    text-align: center;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom: none;
        border-left: none;
        border-right: none;
      }
      &:first-child {
        border-top: none;
        border-left: none;
        border-right: none;
      }
      transition: all 0.3s ease-in-out;
      border: 1px solid $light;
      border-left: none;
      border-right: none;

      &:hover {
        background-color: #f0f0f0 !important;
      }

      &:nth-of-type(odd) {
        background-color: $white !important;

        &:hover {
          background-color: #f0f0f0 !important;
        }
      }
    }

    td {
      vertical-align: middle !important;
      font-size: 15px;
      border: 0 !important;
    }

    .size {
      width: 20%;
    }

    .quantity {
      width: 18%;
    }

    .arrivals {
      width: 18%;
    }

    .price {
      width: 20%;
    }

    .quantity-buy {
      width: 20%;
    }
  }
}

/*
*	Scroll to top
*/

.scroll-to-top {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: $white !important;
  border-radius: $border-radius;
  position: fixed;
  bottom: 48px;
  right: -100%;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  outline: none !important;

  @include mobile {
    bottom: 70px;
    width: 40px;
    height: 40px;
  }

  @include tablet {
    bottom: 75px;
  }

  @include tablet-land {
    bottom: 75px;
  }

  @include laptop-1280 {
    width: 45px;
    height: 45px;
  }

  i {
    font-size: 25px;
    transition: all 0.3s ease-in-out;
    transform: scale(1);

    &::before {
      color: $white;
    }
  }

  &:hover {
    background-color: darken($primary, 5%);

    i {
      transform: scale(1.2) !important;
    }
  }
}

.scroll-to-top.show {
  right: 24px;

  @include mobile {
    right: 8px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  @include tablet {
    right: 8px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  @include tablet-land {
    right: 8px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

/*
*	Toast message
*/

.toast-message {
  position: fixed;
  color: $white;
  background-color: $primary;
  border: 1px solid $primary;
  border-radius: 0;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 17px;
  font-size: 15px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lighten($primary, 10%);
    border-radius: $border-radius;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: darken($primary, 10%);
    }
  }
}

.alert.active {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex !important;
  z-index: 1030;
  opacity: 1;
  margin: 0;
  width: 370px;

  @include mobile {
    width: 90%;
  }
}

.alert {
  border-radius: $border-radius !important;
  display: flex;
  align-items: center;
  padding: 14px 20px;
  padding-left: 15px;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
  min-width: 340px;
  width: 340px;

  @include mobile {
    min-width: 94%;
    width: 94%;
  }

  &:hover {
    opacity: 1;
  }

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $white;
    margin-right: 8px;
    font-size: 24px;
  }
}

.alert-danger {
  background-color: $red;
  color: $white;
  border-color: $red;

  i {
    color: $red;
  }
}

.alert-success {
  background-color: $green;
  color: $white;
  border-color: $green;

  i {
    color: $green;
  }
}

.alert-primary {
  background-color: $blue;
  color: $white;
  border-color: $blue;

  i {
    color: $blue;
  }
}

.alert-info {
  background-color: $yellow;
  color: $white;
  border-color: $yellow;

  i {
    color: $yellow;
  }
}

.alert-temp {
  position: fixed;
  bottom: -100%;
  right: 16px;
  z-index: 9999;
  transition: all 0.6s ease-in-out;
}

.alert-temp.active {
  bottom: 18px;
}

/*
*   Tooltip
*/

.tooltip-custom {
  position: relative;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);

  @include laptop-1280 {
    right: 150px;
  }
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -107px;
  margin-bottom: 20px;
  opacity: 0;
  transition: opacity 0.3s;

  @include laptop-1440 {
    margin-left: -88px;
  }
}

.tooltip-custom .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $black transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.krug-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9999999999;
  opacity: 0.07;
}
.krug-2 {
  z-index: -9999999998;
  opacity: 0.07;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}
